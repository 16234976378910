export const DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'hh:mm';
export const PROFESSIONAL = 'professional';
export const CLIENT = 'client';

export const GUEST_GUARD = 'GUEST GUARD';
export const CLIENT_GUARD = 'CLIENT GUARD';
export const PROFESSIONAL_GUARD = 'PROFESSIONAL GUARD';

export const USER_STORAGE_KEY = 'my-day.user';
export const THEME_STORAGE_KEY = 'my-day.theme';
export const PWA_INSTALL_PROMPT_STORAGE_KEY = 'my-day.pwa_install_prompt';
export const ONBOARDING_STORAGE_KEY = 'my-day.onboarding';
export const BACKEND_TOKEN_STORAGE_KEY = 'my-day.tokens.backend';
export const NO_TZ_UPDATES_STORAGE_KEY = 'my-day.no_tz_updates';
export const PREV_DETECTED_TZ_STORAGE_KEY = 'my-day.detected_tz';

export const CONFIG_SET_THEME = 'CONFIG - SET THEME';
export const CONFIG_SET_RULES = 'CONFIG - SET RULES';
export const CONFIG_FETCH = 'CONFIG - FETCH: Fetch the rules from config';

export const HELPERS_SLEEP = 'HELPERS - SLEEP: Promise timeout';

export const REQUEST_SET_BASE_URL = 'REQUEST - SET BASE URL';
export const REQUEST_END_LOADING = 'REQUEST - END LOADING';
export const REQUEST_START_LOADING = 'REQUEST - START LOADING';
export const REQUEST = 'REQUEST - Make a request with axios';
export const CANCELABLE_REQUEST = 'CANCELABLE_REQUEST - Make a cancelable request with axios';

export const CANCELABLE_REQUEST_CURRENT_REQUEST = 'CANCELABLE_REQUEST currently running request';
export const REQUEST_HEADERS = 'REQUEST - HEADERS: Build the headers for the backend';

// VALIDATION MODULE
export const VALIDATION_SET_MESSAGE = 'VALIDATION - ADD MESSAGE';
export const VALIDATION_REMOVE_MESSAGE = 'VALIDATION - REMOVE MESSAGE';
export const VALIDATION_SET_BAG = 'VALIDATION - SET BAG';
export const VALIDATION_CLEAR_BAGS = 'VALIDATION - CLEAR BAGS';
export const VALIDATION_REMOVE_BAG = 'VALIDATION - REMOVE BAG';
export const VALIDATION_SET_ERROR = 'VALIDATION - SET ERROR';
export const VALIDATION_REMOVE_ERROR = 'VALIDATION - REMOVE ERROR';
export const VALIDATION_CREATE_MESSAGE = 'VALIDATION - CREATE MESSAGE: Create a temporary message';

// REGISTRATION PERSISTENT FORM MODULE
export const REGISTER_SET_EMAIL = 'REGISTER - SET EMAIL';
export const REGISTER_SET_EMAIL_CONFIRMATION = 'REGISTER - SET EMAIL CONFIRMATION';
export const REGISTER_SET_PASSWORD = 'REGISTER - SET PASSWORD';
export const REGISTER_SET_PASSWORD_CONFIRMATION = 'REGISTER - SET PASSWORD CONFIRMATION';
export const REGISTER_SET_USERNAME = 'REGISTER - SET EMAIL USERNAME';
export const REGISTER_SET_AGREE = 'REGISTER - SET AGREE';
export const REGISTER_CLEAR = 'REGISTER - CLEAR';

// AUTH MODULE
export const AUTH_SET_TOKEN = 'AUTH - SET TOKEN';
export const AUTH_SIGNIN = 'AUTH - SIGNIN: Login request with credentials';
export const AUTH_SIGNOUT = 'AUTH - SIGNOUT';
export const AUTH_REGISTER = 'AUTH - REGISTER: Register a new account';
export const AUTH_ACTIVATE = 'AUTH - ACTIVATE: Activate a new account';
export const AUTH_RENEW = 'AUTH - RENEW: Renew auth token';
export const AUTH_FORGOT = 'AUTH - FORGOT: Send password reset link';
export const AUTH_RESET = 'AUTH - RESET: Reset user password with reset token';

// PROFILE MODULE
export const PROFILE_SET_LOCALE = 'PROFILE - SET LOCALE';
export const PROFILE_SET_USER = 'PROFILE - SET USER';
export const PROFILE_LOAD = 'PROFILE - LOAD: Retrieve the logged user from localstorage';
export const PROFILE_FETCH = 'PROFILE - FETCH: Retrieve the logged user';
export const PROFILE_PASSWORD = 'PROFILE - PASSWORD: Change the logged user password';
export const PROFILE_UPDATE = 'PROFILE - UPDATE: Update logged user client/professional info';
export const PROFILE_ONBOARDING_UPDATE =
    'PROFILE - ONBOARDING UPDATE: update the onboarding states of the logged user client/professional';
export const PROFILE_EMAIL_VERIFY = 'PROFILE_EMAIL_VERIFY';
export const PROFILE_EMAIL_RESEND = 'PROFILE_EMAIL_RESEND';
export const PROFILE_TOGGLE_VISIBILITY = 'PROFILE_TOGGLE_VISIBILITY';
export const PROFILE_SUGGEST_TZ = 'PROFILE_SUGGEST_TZ';
export const PROFILE_UPDATE_TIMEZONE = 'PROFILE_UPDATE_TIMEZONE';
export const PROFILE_SET_NO_TZ_UPDATES = 'PROFILE_SET_NO_TZ_UPDATES';
export const PROFILE_SET_PREV_TZ_DETECTED = 'PROFILE_SET_PREV_TZ_DETECTED';
export const PROFILE_SET_UPDATES = 'PROFILE_SET_UPDATES';

export const USER_PATCH = 'USER - PATCH: Set some user information';

// CLIENTS MODULE
export const CLIENTS_SET = 'CLIENTS - SET CLIENTS';
export const CLIENTS_FETCH = 'CLIENTS - CLIENTS_FETCH: Retrieve the clients of a pro.';
export const CLIENTS_LINK_FETCH = 'CLIENTS - CLIENTS_LINK_FETCH: Fetch at invite link of the pro.';
export const CLIENTS_SET_LINK = 'CLIENTS - CLIENTS_SET_LINK: Set the invite link of the pro.';
export const CLIENTS_LOW_SET = 'CLIENTS - CLIENTS_LOW_SET';
export const CLIENTS_LOW_FETCH = 'CLIENTS - CLIENTS_LOW_FETCH: Retrieve the clients running low.';
export const CLIENTS_ADD_CLIENT = 'CLIENTS  - CLIENTS_ADD_CLIENT: Invite a new client';
export const CLIENTS_VOUCHERS_SET = 'CLIENTS_VOUCHERS_SET';
export const CLIENTS_VOUCHERS_FETCH = 'CLIENTS_VOUCHERS_FETCH';
export const PAYMENT_OVERVIEW_SET = 'PAYMENT_OVERVIEW_SET';
export const PAYMENT_OVERVIEW_FETCH = 'PAYMENT_OVERVIEW_FETCH';

// PROFESSIONALS MODULE
export const PROFESSIONALS_SET = 'PROFESSIONALS_SET';
export const PROFESSIONALS_FETCH = 'PROFESSIONALS_FETCH';
export const PROFESSIONALS_VOUCHERS_SET = 'PROFESSIONALS_VOUCHERS_SET';
export const PROFESSIONALS_VOUCHERS_FETCH = 'PROFESSIONALS_VOUCHERS_FETCH';

// VOUCHERS MODULE
export const VOUCHERS_TYPES_SET = 'VOUCHERS - SET VOUCHER TYPES';
export const VOUCHERS_TYPES_FETCH = 'VOUCHERS - VOUCHERS_TYPES_FETCH: Retrieve the voucher types';
export const VOUCHERS_SETTINGS_FETCH =
    'VOUCHERS - VOUCHERS_SETTINGS_FETCH: Retrieve the voucher settings';
export const VOUCHERS_SETTINGS_SET =
    'VOUCHERS - VOUCHERS_SETTINGS_SET: Retrieve the voucher settings';
export const VOUCHERS_SETTINGS_UPDATE =
    'VOUCHERS - VOUCHER_SETTINGS_UPDATE: Update the voucher settings';

export const VOUCHERS_CLIENT_FETCH =
    'VOUCHERS - VOUCHERS_CLIENT_FETCH: fetch the vouchers for a specific client';

// ONBOARDING MODULE
export const ONBOARDING_SHOW_STATE_SET = 'VOUCHERS - SET ONBOARDING SHOW STATE';
export const ONBOARDING_SLIDE_SET = 'VOUCHERS - SET ONBOARDING SLIDE';

// CALENDAR MODULE
export const CALENDAR_SHOWN_DAYS_SET = 'CALENDAR_SHOWN_DAYS_SET';
export const CALENDAR_SET = 'CALENDAR_SET';
export const CALENDAR_CALENDARS_FETCH = 'CALENDAR_CALENDARS_FETCH';
export const CALENDAR_CALENDARS_SET = 'CALENDAR_CALENDARS_SET';
export const CALENDAR_SELECTED_CALENDARS_SET = 'CALENDAR_SELECTED_CALENDARS_SET';
export const CALENDAR_EVENTS_FETCH = 'CALENDAR_EVENTS_FETCH';
export const CALENDAR_EVENTS_SET = 'CALENDAR_EVENTS_SET';
export const CALENDAR_LOADING_SET = 'CALENDAR_LOADING_SET';
export const CALENDAR_SLOTS_FETCH = 'CALENDAR_SLOTS_FETCH';
export const CALENDAR_SLOTS_SET = 'CALENDAR_SLOTS_SET';
export const CALENDAR_PARAMS_SET = 'CALENDAR_PARAMS_SET';
export const CALENDAR_DRAFT_EVENT_SET = 'CALENDAR_DRAFT_EVENT_SET';
export const CALENDAR_BOOK_SLOT = 'CALENDAR_BOOK_SLOT';
export const CALENDAR_REGISTER = 'CALENDAR_REGISTER';

// EMAILS MODULE
export const EMAILS_UNSUBSCRIBE = 'EMAILS_UNSUBSCRIBE';

// INVITE MODULE
export const INVITE_SET = 'INVITE_SET - save the slug passed in to refer to the pending invite';
export const INVITE_REMOVE = 'INVITE_REMOVE - remove the pending invite';
export const INVITE_REG_SET = 'INVITE_REG_SET - save the URL when invited by a professional';
export const INVITE_CLIENT_SET = 'INVITE_CLIENT_SET - set data of the invited client';
export const INVITE_STORAGE_KEY = 'INVITE_STORAGE_KEY';
export const INVITE_FETCH_PROFESSIONAL = 'INVITE_FETCH_PROFESSIONAL';
export const INVITE_SET_PROFESSIONAL = 'INVITE_FETCH_PROFESSIONAL';
export const INVITE_ACCEPT = 'INVITE_ACCEPT';

// PWAINSTALLPROMPT MODULE
export const PWAINSTALLPROMPT_EVENT_SET = 'PWAINSTALLPROMPT - SET EVENT';

// GROUPS MODULE
export const GROUPS_SET = 'GROUP_SET - SET GROUPS';
export const GROUPS_FETCH = 'GROUPS - GROUPS_FETCH: Retrieve the groups of a pro.';
export const GROUPS_ADD_GROUP = 'GROUPS - ADD_GROUP: add a new group';

export const SELECTED_LANGUAGE = 'SELECTED_LANGUAGE';

// Social media
export const SOCIAL_MEDIA_KEYS = [
    'facebook',
    'instagram',
    'telegram',
    'twitter',
    'youtube',
    'linkedin',
];
Object.freeze(SOCIAL_MEDIA_KEYS);
