import { REQUEST, PAYMENT_OVERVIEW_FETCH, PAYMENT_OVERVIEW_SET } from '@/constants';

export default {
    state: {
        paymentOverview: {},
    },
    getters: {},
    mutations: {
        [PAYMENT_OVERVIEW_SET](state, paymentOverview) {
            state.paymentOverview = paymentOverview;
        },
    },
    actions: {
        async [PAYMENT_OVERVIEW_FETCH]({ rootState, commit, dispatch }) {
            commit(PAYMENT_OVERVIEW_SET, {});
            const responsePaymentOverview = await dispatch(REQUEST, {
                url: 'vouchers/payment-overview',
            });
            if (responsePaymentOverview.status !== 200) return responsePaymentOverview;

            const paymentOverviewVouchers = responsePaymentOverview.data.data;

            const paymentOverviews = {};
            const parsePaymentOverview = (client) =>
                (paymentOverviews[client.id] = makePaymentOverview(
                    paymentOverviewVouchers.filter((voucher) => voucher.client_id === client.id),
                ));

            rootState.clients.clients?.forEach((client) => parsePaymentOverview(client));
            commit(PAYMENT_OVERVIEW_SET, paymentOverviews);
        },
    },
};

const makePaymentOverview = (vouchers) => {
    const voucherBatches = new Map();

    for (const voucher of vouchers) {
        if (voucher.is_credit) continue;

        const voucherKey = `${voucher.created_at}_${voucher.duration}`;
        const batch = voucherBatches.get(voucherKey);

        if (batch) {
            batch.ids.push(voucher.id);
            batch.count++;

            if (batch.paid ^ voucher.paid) {
                console.warn({
                    message: 'Only a part of the vouchers in a batch are paid',
                    voucher,
                    batch,
                });
                batch.paid &&= voucher.paid;
            }
        } else {
            voucherBatches.set(voucherKey, {
                ids: [voucher.id],
                count: 1,
                paid: voucher.paid,
                duration: voucher.duration,
                createdAt: voucher.created_at,
                type: voucher.type,
            });
        }
    }
    return voucherBatches;
};
