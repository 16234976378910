import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import en from './en.json';
import es from './es.json';
import pt from './pt.json';
import ptBR from './pt-br.json';

export const messages = {
    en,
    es,
    pt,
    'pt-br': ptBR,
};

export const locale = 'en';
export const fallbackLocale = 'en';

export const i18n = new VueI18n({
    locale,
    fallbackLocale,
    messages,
});

/**
 * @typedef {'en' | 'pt' | 'pt-br' | 'es'} Locale
 * @type { Locale[] }
 */
export const localeOptions = ['en', 'pt', 'pt-br', 'es'];

export function appendLocale(string) {
    return string + '-' + i18n.locale;
}

export default i18n;
