import {
    REQUEST,
    CLIENTS_SET,
    CLIENTS_FETCH,
    CLIENTS_LOW_SET,
    CLIENTS_LOW_FETCH,
    CLIENTS_ADD_CLIENT,
    CLIENTS_VOUCHERS_SET,
    CLIENTS_VOUCHERS_FETCH,
    CLIENTS_LINK_FETCH,
    CLIENTS_SET_LINK,
} from '@/constants';

export default {
    state: {
        clients: null,
        clientsRunningLow: null,
        vouchers: {},
        inviteLink: null,
    },
    getters: {},
    mutations: {
        [CLIENTS_SET](state, clients) {
            state.clients = clients;
        },
        [CLIENTS_SET_LINK](state, link) {
            state.inviteLink = link;
        },
        [CLIENTS_LOW_SET](state, clients) {
            state.clientsRunningLow = clients;
        },
        [CLIENTS_VOUCHERS_SET](state, vouchers) {
            state.vouchers = vouchers;
        },
    },
    actions: {
        async [CLIENTS_FETCH]({ commit, dispatch }, params) {
            commit(CLIENTS_SET, null);
            const response = await dispatch(REQUEST, {
                url: 'clients',
                params,
            });
            if (response.status === 200) {
                commit(CLIENTS_SET, response.data.data);
            }
            return response;
        },
        async [CLIENTS_LINK_FETCH]({ commit, dispatch }, params) {
            const getResponse = await dispatch(REQUEST, {
                url: 'invite-link',
                params,
            });

            if (getResponse.status !== 200) {
                return getResponse;
            }

            // if a link already exists, use it
            const link = getResponse.data.data.invite_link;
            const hasSlug = link.split('/invite').pop() !== '/';
            if (hasSlug) {
                commit(CLIENTS_SET_LINK, getResponse.data.data.invite_link);
                return getResponse;
            }

            // if no link exists, create one
            const putResponse = await dispatch(REQUEST, {
                method: 'put',
                url: 'invite-link',
            });

            if (putResponse.status !== 200) {
                return putResponse;
            }

            // use the newly created link
            if (putResponse.data.data['invite-link']) {
                commit(CLIENTS_SET_LINK, putResponse.data.data['invite-link']);
            }

            return putResponse;
        },
        async [CLIENTS_LOW_FETCH]({ commit, dispatch }, params) {
            commit(CLIENTS_LOW_SET, null);
            const response = await dispatch(REQUEST, {
                url: 'running-low',
                params,
            });
            if (response.status === 200) {
                // Flatten the running low resource to match the client structure and set defaults
                const clients = response.data.data.map((clientRunningLow) => {
                    return {
                        runningLowResourceId: clientRunningLow.id,
                        ...clientRunningLow,
                        ...clientRunningLow.client,
                        voucherType: clientRunningLow.type.id,
                        duration: clientRunningLow.duration,
                        amount: 5,
                    };
                });

                commit(CLIENTS_LOW_SET, clients);
            }
            return response;
        },
        async [CLIENTS_ADD_CLIENT]({ dispatch }, data) {
            const response = await dispatch(REQUEST, {
                method: 'post',
                url: 'clients',
                data,
            });
            if (response.status === 200) {
                dispatch(CLIENTS_FETCH);
            }
            return response;
        },
        async [CLIENTS_VOUCHERS_FETCH]({ state, rootState, commit, dispatch }) {
            commit(CLIENTS_VOUCHERS_SET, {});
            const responseVouchers = await dispatch(REQUEST, {
                url: 'vouchers',
            });

            if (responseVouchers.status !== 200) return responseVouchers;

            const vouchers = {};
            const remainingVouchers = responseVouchers.data.data;
            const durations = rootState.vouchers.durations;
            const parseVouchers = (client) => {
                vouchers[client.id] = rootState.vouchers.voucherTypes?.reduce((typesDict, type) => {
                    typesDict[type.id] = {
                        type,
                        totalCount: countVouchersByType(remainingVouchers, client.id, type.id),
                        counts: durations.reduce((durationsDict, duration) => {
                            durationsDict[duration.value] = countVouchersByTypeAndDuration(
                                remainingVouchers,
                                client.id,
                                type.id,
                                duration.value,
                            );
                            return durationsDict;
                        }, {}),
                    };
                    return typesDict;
                }, {});
            };

            state.clients?.forEach((client) => parseVouchers(client));
            state.clientsRunningLow?.forEach((client) => parseVouchers(client));
            commit(CLIENTS_VOUCHERS_SET, vouchers);

            return responseVouchers;
        },
    },
};

const countVouchersByType = (unusedVouchers, clientId, typeId) => {
    return unusedVouchers.reduce(
        (count, voucher) =>
            voucher.client_id === clientId && voucher.type?.id === typeId
                ? count + voucher.value
                : count,
        0,
    );
};

const countVouchersByTypeAndDuration = (unusedVouchers, clientId, typeId, duration) => {
    return unusedVouchers.reduce(
        (count, voucher) =>
            voucher.client_id === clientId &&
            voucher.type?.id === typeId &&
            voucher.duration === duration
                ? count + voucher.value
                : count,
        0,
    );
};
