import decode from 'jwt-decode';
import {
    BACKEND_TOKEN_STORAGE_KEY,
    AUTH_SET_TOKEN,
    AUTH_SIGNIN,
    AUTH_SIGNOUT,
    AUTH_REGISTER,
    AUTH_ACTIVATE,
    AUTH_RENEW,
    AUTH_FORGOT,
    AUTH_RESET,
    PROFILE_SET_USER,
    REQUEST,
    VALIDATION_CREATE_MESSAGE,
    SELECTED_LANGUAGE,
    USER_PATCH,
} from '@/constants';
import i18n from '@/languages';
import moment from 'moment';

export default {
    state: {
        token: localStorage.getItem(BACKEND_TOKEN_STORAGE_KEY),
    },
    getters: {
        isAuthenticated: (state) => {
            if (!state.token) return false;
            const payload = decode(state.token);
            payload.exp = new Date(payload.exp * 1000);
            payload.iat = new Date(payload.iat * 1000);
            return payload.exp > new Date() && payload.exp > payload.iat;
        },
    },
    mutations: {
        [AUTH_SET_TOKEN](state, token) {
            state.token = token;
            if (token) {
                localStorage.setItem(BACKEND_TOKEN_STORAGE_KEY, token);
            } else {
                localStorage.removeItem(BACKEND_TOKEN_STORAGE_KEY);
            }
        },
    },
    actions: {
        async [AUTH_SIGNIN]({ commit, dispatch }, data) {
            let response = await dispatch(REQUEST, {
                method: 'post',
                url: 'auth/login',
                data,
                hideToast: true,
            });

            if (response.status === 200) {
                commit(AUTH_SET_TOKEN, response.data.data.token);
                //if user changes the language on the login page, change his default profile language
                const language =
                    localStorage.getItem(SELECTED_LANGUAGE) || response.data.data.user.language;
                response.data.data.user.language = language;
                dispatch(USER_PATCH, { language: language });
                commit(PROFILE_SET_USER, response.data.data.user);
                moment.locale(language);
                return response;
            }

            if (response.status === 401) {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'error',
                    message: 'common.auth.credentials',
                });
            } else {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'error',
                    message: response?.data?.data?.errors[0]?.message || 'network_errors.unknown',
                });
            }

            return response;
        },
        async [AUTH_SIGNOUT]({ commit, dispatch }) {
            await dispatch(REQUEST, {
                method: 'post',
                url: 'auth/logout',
                hideToast: true,
            });

            commit(AUTH_SET_TOKEN, null);
            commit(PROFILE_SET_USER, null);
        },
        async [AUTH_REGISTER]({ dispatch }, data) {
            let response = await dispatch(REQUEST, {
                method: 'post',
                url: 'auth/register',
                data,
            });
            return response;
        },
        async [AUTH_ACTIVATE]({ commit, dispatch }, data) {
            let response = await dispatch(REQUEST, {
                method: 'post',
                url: data.path,
                data,
            });

            if (response.status === 200) {
                commit(AUTH_SET_TOKEN, response.data.data.token);
                commit(PROFILE_SET_USER, response.data.data.user);
            }

            if (response.status === 403) {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'error',
                    message: 'common.auth.invalid_activation_link',
                });
            }

            return response;
        },
        async [AUTH_RENEW]({ commit, dispatch }, token) {
            let requestConfig = {
                url: 'auth/refresh',
                method: 'post',
            };

            // Make renew action to be more general and allow renewal of an optional token
            if (token) {
                // If a specific token is provided, clear out the old token and user
                commit(AUTH_SET_TOKEN, null);
                commit(PROFILE_SET_USER, null);

                requestConfig.headers = { authorization: `Bearer ${token}` };
            }

            let response = await dispatch(REQUEST, requestConfig);

            if (response.status === 200) {
                commit(AUTH_SET_TOKEN, response.data.data.token);
                commit(PROFILE_SET_USER, response.data.data.user);
                i18n.locale = response.data.data.user.language;
                return response;
            }

            if (response.status === 401) {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'error',
                    message: 'common.auth.invalid_login_token',
                });
            } else {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'error',
                    message: response?.data?.data?.errors[0]?.message || 'network_errors.unknown',
                });
            }

            return response;
        },
        async [AUTH_FORGOT]({ dispatch }, data) {
            let response = await dispatch(REQUEST, {
                method: 'post',
                url: 'auth/forgot',
                data,
            });
            return response;
        },
        async [AUTH_RESET]({ commit, dispatch }, data) {
            let response = await dispatch(REQUEST, {
                method: 'post',
                url: 'auth/reset/',
                data,
                hideToast: true,
            });
            if (response.status === 200) {
                commit(AUTH_SET_TOKEN, response.data.data.token);
                commit(PROFILE_SET_USER, response.data.data.user);
            }

            if (response.status === 422) {
                if (
                    response.data &&
                    response.data.data.errors[0] &&
                    response.data.data.errors[0].field === 'token'
                ) {
                    dispatch(VALIDATION_CREATE_MESSAGE, {
                        type: 'error',
                        message: 'common.auth.invalid_reset_token',
                    });
                } else {
                    dispatch(VALIDATION_CREATE_MESSAGE, {
                        type: 'error',
                        message: 'network_errors.422',
                    });
                }
            }
            return response;
        },
    },
};
