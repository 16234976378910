import Vue from 'vue';
import VueRouter from 'vue-router';
import guestRoutes from './routes/guest';
import clientRoutes from './routes/client';
import professionalRoutes from './routes/professional';
import store from '@/store';
import {
    GUEST_GUARD,
    CLIENT_GUARD,
    PROFESSIONAL_GUARD,
    CONFIG_SET_THEME,
    VALIDATION_CLEAR_BAGS,
    THEME_STORAGE_KEY,
} from '@/constants';
import i18n, { localeOptions } from '@/languages';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        clientRoutes,
        professionalRoutes,
        guestRoutes,
        {
            path: '/bye',
            name: 'Bye',
            component: () => import('@/pages/Bye'),
        },
        {
            path: '/error/:message/:retryPath',
            name: 'Error',
            component: () => import('@/pages/Error'),
        },
        {
            path: '**',
            name: 'PageNotFound',
            component: () => import('@/pages/PageNotFound'),
        },
        {
            path: '**',
            name: 'ProfileNotPublic',
            component: () => import('@/pages/ProfileNotPublic'),
            props: true,
        },
        {
            path: '/landingPage',
            name: 'LandingPage',
            beforeEnter() {
                window.location.replace('https://www.myday.me/' + i18n.locale);
            },
        },
    ],
});

/*
    Update service worker on route changes in order
    to handle lazy loaded chunks from the new deploy
 */
router.beforeEach(async (_from, _to, next) => {
    try {
        const registrations = await navigator.serviceWorker?.getRegistrations();
        if (registrations && registrations.length) {
            registrations[0].update();
        }
    } catch (err) {
        console.warn(err);
    } finally {
        next();
    }
});

/*
    If path is prefixed by /{language code}/,
    update the locale and pop the language code from the path
*/
router.beforeEach((to, from, next) => {
    const supportedLanguages = localeOptions;

    let pathParts = to.path.split('/');
    let pathPrefix = pathParts.length > 1 && pathParts[1];

    if (supportedLanguages.includes(pathPrefix)) {
        // Now pathPrefix is confirmed to be a language code
        i18n.locale = pathPrefix;
        document.documentElement.setAttribute('lang', pathPrefix);
        // Pop language code
        pathParts.splice(1, 1);
        next({ path: pathParts.join('/') });
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    // Redirect to the home path of the previously used theme except when
    // navigating to the other home path intentionally
    const previousTheme = localStorage.getItem(THEME_STORAGE_KEY);

    if (to.name === 'ClientHome' && from.name !== 'ProfessionalHome') {
        if (previousTheme === 'professional') {
            next({ name: 'ProfessionalHome' });
            return;
        }
    }

    next();
    return;
});

router.beforeEach((to, from, next) => {
    store.commit(VALIDATION_CLEAR_BAGS);
    let theme = to.matched.find((m) => m.meta.theme)?.meta.theme;

    // if "to" is one of the next routes do not preserve previous theme
    const forceRoutesTheme = [...clientRoutes.children, ...guestRoutes.children]
        .filter(({ meta }) => meta && meta.forceRouteTheme)
        .map((route) => route.name);

    if (store.getters.isAuthenticated) {
        const previousTheme = localStorage.getItem(THEME_STORAGE_KEY);

        // don't allow user to access routes out of the scope of previous theme
        if (from.name === null && from.path === '/' && !forceRoutesTheme.includes(to.name)) {
            store.commit(CONFIG_SET_THEME, previousTheme);
            if (
                (theme && previousTheme && theme !== previousTheme) ||
                to.name === 'ClientAuthLogIn'
            ) {
                next({
                    name: store.getters.isThemeClient ? 'ClientCalendar' : 'ProfessionalCalendar',
                });
                return;
            }
        }
        store.commit(CONFIG_SET_THEME, theme ?? previousTheme);
        if (to.meta.guard === GUEST_GUARD) {
            next({ name: store.getters.isThemeClient ? 'ClientCalendar' : 'ProfessionalCalendar' });
            return;
        }
    } else {
        if (to.meta.guard === CLIENT_GUARD) {
            next({ name: 'ClientAuthLogIn', query: { redirect: to.fullPath } });
            return;
        }

        if (to.meta.guard === PROFESSIONAL_GUARD) {
            next({ name: 'ProfessionalAuthLogIn', query: { redirect: to.fullPath } });
            return;
        }
    }
    next();
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.debugRoute)) {
        if (!store.state.config.rules.debug) {
            next({ path: '/' });
        } else {
            next();
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.path === '/client') {
        next({ path: '/' });
    } else {
        next();
    }
});

export default router;
